const OutOfPower = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#40FB76" fillOpacity="0.5"/>
      <path
        d="M79.6296 89.1953C78.1801 89.1953 77 90.3754 77 91.8249C77 93.2744 78.1801 94.4609 79.6296 94.4609C81.0791 94.4609 82.2656 93.2808 82.2656 91.8249C82.2656 90.369 81.0855 89.1953 79.6296 89.1953Z"
        fill="#05293D"/>
      <path d="M77.0644 73H82.1953V86.924H77.0644V73Z" fill="#05293D"/>
      <path
        d="M79.5 102C89.7173 102 98 93.7173 98 83.5C98 73.2827 89.7173 65 79.5 65C69.2827 65 61 73.2827 61 83.5C61 93.7173 69.2827 102 79.5 102Z"
        stroke="#05293D" strokeWidth="4" strokeMiterlimit="10"/>
      <rect x="48" y="35" width="64" height="97" stroke="#05293D" strokeWidth="4"/>
      <rect x="63" y="26" width="33" height="9" stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default OutOfPower;

