const Cog = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#4C78EC" fillOpacity="0.5"/>
      <path
        d="M92.2386 131H66.7614V116.07C62.3337 114.502 58.2327 112.13 54.664 109.074L41.7386 116.55L29 94.45L41.9212 86.9913C41.0724 82.3691 41.0724 77.6309 41.9212 73.0087L29 65.55L41.7386 43.45L54.664 50.9258C58.2327 47.8697 62.3337 45.4982 66.7614 43.9303V29H92.2386V43.9303C96.6663 45.4982 100.767 47.8697 104.336 50.9258L117.261 43.45L130 65.55L117.079 73.0087C117.928 77.6309 117.928 82.3691 117.079 86.9913L130 94.45L117.261 116.55L104.336 109.078C100.767 112.133 96.666 114.503 92.2386 116.07V131Z"
        stroke="#05293D" strokeWidth="4"/>
      <path
        d="M80.1908 101.382C91.8941 101.382 101.382 91.8941 101.382 80.1908C101.382 68.4874 91.8941 59 80.1908 59C68.4874 59 59 68.4874 59 80.1908C59 91.8941 68.4874 101.382 80.1908 101.382Z"
        stroke="#05293D" strokeWidth="4" strokeMiterlimit="10"/>
    </svg>


  )
}

export default Cog;

