const Leaf = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#40FB76" fillOpacity="0.5"/>
      <path d="M80 28L80 136" stroke="#05293D" strokeWidth="4"/>
      <path d="M66 85L79.7886 98.7886" stroke="#05293D" strokeWidth="4"/>
      <path d="M66 61L79.7886 74.7886" stroke="#05293D" strokeWidth="4"/>
      <path d="M93.5781 85L79.7895 98.7886" stroke="#05293D" strokeWidth="4"/>
      <path d="M93.7891 61L80.0005 74.7886" stroke="#05293D" strokeWidth="4"/>
      <path
        d="M80 27.0623C80.008 27.0718 80.0161 27.0813 80.0242 27.0908C81.0005 28.2371 82.3974 29.8995 84.0746 31.9576C87.4308 36.0758 91.9014 41.7686 96.3678 48.0754C100.839 54.3894 105.279 61.2797 108.592 67.7975C111.939 74.3804 114 80.3247 114 84.8238C114 103.708 98.7685 119 80 119C61.2315 119 46 103.708 46 84.8238C46 80.3247 48.0612 74.3804 51.4078 67.7975C54.7213 61.2797 59.1606 54.3894 63.6322 48.0754C68.0986 41.7686 72.5692 36.0758 75.9254 31.9576C77.6026 29.8995 78.9995 28.2371 79.9758 27.0908C79.9839 27.0813 79.992 27.0718 80 27.0623Z"
        stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default Leaf;

