const Umbrella = () => {

  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#40FB76" fillOpacity="0.5"/>
      <path d="M80 83V123.238C80 138 94 138 94 138" stroke="#05293D" strokeWidth="4"/>
      <path
        d="M26.0019 81.1602C27.9041 53.1504 51.3457 31 80 31C108.654 31 132.096 53.1504 133.998 81.1602C134.025 81.5589 133.693 82 133.125 82H26.8754C26.307 82 25.9748 81.5589 26.0019 81.1602Z"
        stroke="#05293D" strokeWidth="4"/>
      <path d="M80 30L80 22" stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default Umbrella;

