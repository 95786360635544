const Home = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#17E8DA" fillOpacity="0.5"/>
      <rect x="46" y="64" width="67" height="65" stroke="#05293D" strokeWidth="4"/>
      <path d="M131 79.2051L79.9974 28.2025" stroke="#05293D" strokeWidth="4" strokeLinecap="square"/>
      <path d="M28.7871 79.002L79.7897 27.9994" stroke="#05293D" strokeWidth="4" strokeLinecap="square"/>
    </svg>


  )
}

export default Home;

