const KW = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#17E8DA" fillOpacity="0.5"/>
      <path d="M55 89L87 38V71H104L72 122V89H55Z" stroke="#05293D" strokeWidth="4" strokeLinecap="square"/>
    </svg>


  )
}

export default KW;

