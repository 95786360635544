const Grants = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#40FB76" fillOpacity="0.5"/>
      <path
        d="M28 132V127.304H38.4015V76.2721C38.4015 71.6107 39.3376 67.5407 41.2099 64.0621C43.1515 60.5834 45.8212 57.8701 49.219 55.9221C52.6861 53.974 56.6734 53 61.1807 53C65.2719 53 68.9817 53.7653 72.3102 55.2959C75.708 56.8265 78.5164 59.0876 80.7354 62.0793C82.9544 65.0013 84.3759 68.6191 85 72.9326L80.3193 73.9762C79.6259 68.8278 77.5803 64.8274 74.1825 61.9749C70.854 59.1224 66.5201 57.6962 61.1807 57.6962C55.9106 57.6962 51.646 59.3659 48.3869 62.7054C45.1971 66.0449 43.6022 70.5671 43.6022 76.2721V127.304H79.9033V132H28ZM28 94.8481V90.1519H64.4051V94.8481H28Z"
        fill="#05293D"/>
      <path
        d="M117.5 59C127.717 59 136 50.7173 136 40.5C136 30.2827 127.717 22 117.5 22C107.283 22 99 30.2827 99 40.5C99 50.7173 107.283 59 117.5 59Z"
        stroke="#05293D" strokeWidth="4" strokeMiterlimit="10"/>
      <path
        d="M114.518 49.8836L105.241 40.6069L108.393 37.4552L114.518 43.5802L126.741 31.3613L129.893 34.513L114.518 49.8836Z"
        fill="#05293D"/>
    </svg>


  )
}

export default Grants;

