const Glossary = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#D050E9" fillOpacity="0.5"/>
      <path
        d="M35.5283 68.0002L48.5843 32.2402H52.7123L65.7683 68.0002H61.7363L58.6643 59.2642H42.6803L39.5603 68.0002H35.5283ZM43.9283 55.6642H57.3203L50.0723 35.1202H51.2243L43.9283 55.6642Z"
        fill="#05293D"/>
      <path d="M96.0563 127V124.408L116.504 94.8402H97.2563V91.2402H121.544V93.8322L101.144 123.4H121.976V127H96.0563Z"
            fill="#05293D"/>
      <rect x="22" y="22" width="58" height="58" stroke="#05293D" strokeWidth="4"/>
      <rect x="80" y="80" width="58" height="58" stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default Glossary;

