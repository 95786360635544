const Parking = () => {

  return (


    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#17E8DA" fillOpacity="0.5"/>
      <path
        d="M66 84V39H81.3061C84.2041 39 86.7551 39.5638 88.9592 40.6913C91.1633 41.8188 92.8775 43.4094 94.102 45.4631C95.3673 47.5168 96 49.8926 96 52.5906C96 55.2886 95.3673 57.6644 94.102 59.7181C92.8775 61.7315 91.1633 63.3221 88.9592 64.4899C86.7959 65.6175 84.2449 66.1812 81.3061 66.1812H69.9796V84H66ZM69.9796 62.557H81.4286C83.5102 62.557 85.3469 62.1544 86.9388 61.349C88.5306 60.5034 89.7755 59.3356 90.6735 57.8456C91.5714 56.3154 92.0204 54.5436 92.0204 52.5302C92.0204 50.5168 91.5714 48.7852 90.6735 47.3356C89.7755 45.8456 88.5306 44.698 86.9388 43.8926C85.3469 43.047 83.5102 42.6242 81.4286 42.6242H69.9796V62.557Z"
        fill="#05293D"/>
      <rect x="43" y="25" width="73" height="70" stroke="#05293D" strokeWidth="4"/>
      <path d="M79 97L79 136" stroke="#05293D" strokeWidth="4"/>
      <path d="M91.5 136L67 136" stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default Parking;

