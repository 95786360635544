const ACDC = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="8" fill="#17E8DA" fillOpacity="0.5"/>
      <path
        d="M26 46.6323L26.8597 45.0283C33.3119 32.9906 43.9076 32.9906 50.3598 45.0283L51.2195 46.6323C57.1995 57.7892 67.02 57.7892 73 46.6323"
        stroke="#05293D" strokeWidth="4" strokeLinecap="square"/>
      <line x1="27.6297" y1="129.586" x2="129.718" y2="27.4973" stroke="#05293D" strokeWidth="4"/>
      <line x1="85" y1="107" x2="132" y2="107" stroke="#05293D" strokeWidth="4"/>
      <line x1="115" y1="120" x2="132" y2="120" stroke="#05293D" strokeWidth="4"/>
      <line x1="85" y1="120" x2="102" y2="120" stroke="#05293D" strokeWidth="4"/>
    </svg>


  )
}

export default ACDC;

